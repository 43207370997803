.border-right{
    display: block;
}

.text-center{
    text-align: center;
}
body{
    font-size: 16px;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}