.nav-link{
    color: rgb(0, 0, 0) !important;
    float: left;
    text-align: center;
    padding: 12px;
    text-decoration: none;
    font-size: 20px;
    line-height: 25px;
    border-radius: 4px;
}

.bg-dark{
    background-color: #f5e61c !important;
    position: fixed;
    top: 0 px;
    transition: top 0.1s;
    z-index: 99;
    width: 100%;
    
}

.bg-dark a:hover{
    background-color:#ddd;
    color:black;
}

.nav-link a:hover{
    background-color: #ddd;
    color:black;
}

.navbar-right{
    float: right;
}

